import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

import NAWATELogoImage from "../img/nawate-logo.svg";
import NAWATELogoSmallImage from "../img/nawate-logo-small.svg";
import ToriiLogoImage from "../img/torii-logo.svg";
import KadoLogoImage from "../img/kado-logo.svg";

import InstagramImage from "../img/Instagram.svg";

const HumburgerMenu = ({open, setOpen}) =>{
  return (
    <MenuDiv open={open} setOpen={setOpen}>
      <CloseDiv onClick={() => {setOpen(!open)}}>
        <CloseMark />
        <CloseSpan>CLOSE</CloseSpan>
      </CloseDiv>
      <StyledLink to="/">
            〉トップページ
        </StyledLink>
        <StyledLink to="/facility">
            〉施設案内
        </StyledLink>
        <StyledLink to="/project">
            〉プロジェクト
        </StyledLink>
        <StyledLink to="/aboutus">
            〉私たちのこと
        </StyledLink>
        <StyledLink to="/archive">
            〉アーカイブ
        </StyledLink>
        <StyledLink to="/news">
            〉おしらせ・イベント
        </StyledLink>
        <MultiColumnDiv>
          <FlexA href="https://www.instagram.com/nawate_project/" target="_blank">
          <InstagramLogo src={InstagramImage}></InstagramLogo>
          </FlexA>
          <FlexA href="https://shopnawate.stores.jp" target="_blank">
          <ShopButton>🛒 ONLINE SHOP</ShopButton>
          </FlexA>
          {/*
          <ShopButton><img src={ShopIcon} /> ONLINE SHOP</ShopButton>
          */}
        </MultiColumnDiv>
        <MultiColumnDiv>
          <img src={NAWATELogoSmallImage}></img>
          <ToriiLogo src={ToriiLogoImage}></ToriiLogo>
          <img src={KadoLogoImage}></img>
        </MultiColumnDiv>
    </MenuDiv>
  );
};

export default HumburgerMenu;

const MenuDiv = styled.div.attrs(props => ({
    open: props.open,
}))`
  display: ${props => props.open ? "flex" : "none"};
  flex-direction: column;
  position: ${props => props.open ? "fixed" : ""};
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #E7D0A9;
  line-height: 3em;
  padding: 0 5vw 0 5vw;
`;

const MultiColumnDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 5vh 10vw 0 10vw;
`;

const FlexA = styled.a`
  display: flex;
`;

const StyledLink = styled(props => <Link {...props} />)`
  font-weight: 700;
  font-size: 14pt;
  text-decoration: none;
  color: #2b2523;
  &:hover{
    text-decoration: underline;
    text-decoration-color: #666666;
    text-underline-offset: 5px;
    text-decoration-thickness: 4px;
  }
`;

const InstagramLogo = styled.img`
  width: 15vw;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all  0.3s ease;
  &:hover{
    opacity: 0.5;
  }
`;

const ShopButton = styled.button`
  background: #333333;
  width: 45vw;
  height: 8vh;
  margin: 0 0 0 2em;
  border: none;
  border-radius: 50px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 12pt;
  color: #E7D0A9;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all  0.3s ease;

  &:hover {
    background-color: rgba(0,0,0,0);
    border: 1px #666666 solid;
    color: #666666;
    pointer: cursor;
  }
`;

const ToriiLogo = styled.img`
  margin: 40px 30px 40px 30px; 
  width: 100px;
  height: 30px;
`;

const CloseDiv = styled.div`
  text-align: right;
`;

const CloseMark = styled.div`
  display: block;
  background: #333333;
  border-radius: 2px;
  width: 2.5em;
  height: 5px;
  margin: 1.5vh 0 0 auto;
`;

const CloseSpan = styled.span`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 9pt;
  margin: 0 1vw 0 0;
`;