import React, {useState} from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

import NAWATELogoImage from "../img/nawate-logo.svg";
import NAWATELogoSmallImage from "../img/nawate-logo-small.svg";
import ToriiLogoImage from "../img/torii-logo.svg";
import KadoLogoImage from "../img/kado-logo.svg";

import Humburger from "./Humburger";

const Navbar = () =>{
  const [open, setOpen] = useState(false);

  return (
    <NavDiv>
      <TextDiv>
        <br />
        岡山市・奉還町4丁目からはじまる<br />
        これからのまち
      </TextDiv>
      <NAWATELogoBig src={NAWATELogoImage} onClick={()=>{navigate("/")}}></NAWATELogoBig>
      <LogosDiv>
        <br />
        <img src={NAWATELogoSmallImage}></img>
        <ToriiLogo src={ToriiLogoImage}></ToriiLogo>
        <img src={KadoLogoImage}></img>
      </LogosDiv>
      <Humburger open={open} setOpen={setOpen} />
    </NavDiv>
  );
};

export default Navbar;

const NavDiv = styled.div`
  display: flex;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    margin: 0 1vw 2vh 1vw;
    padding: 1vh 0 0 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    justify-content: space-between;
    margin: 0 5vw 3vh 5vw;
    padding: 3vh 0 0 0;
  `}
`;

const TextDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 30vw;
    font-size: 5pt;
    margin: 0 5vw 0 3vw;
  `}
`;

const LogosDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: none;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
  `}
`;

const NAWATELogoBig = styled.img`
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all  0.3s ease;
  &:hover{
    opacity: 0.5;
  }
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 25vw;
  `}
`;

const ToriiLogo = styled.img`
  margin: 40px 30px 0 30px; 
  width: 100px;
  height: 30px;
`;