import React from "react";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import Navbar from "../components/Navbar";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import FixedButton from "../components/FixedButton";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { navigate, withPrefix } from "gatsby";
import styled from "styled-components";
import "@fontsource/noto-sans-jp"


const TemplateWrapper = ({ children, crumbs, crumbLabel }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/NAWATELogo.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/NAWATELogo.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/NAWATELogo.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>
      <AllCss>
        <Navbar />
        <Menu />
        <FixedButton color="#00cc33" position="10" words="宿泊予約" img="bed" to="/form/stay" />
        <FixedButton color="#ffcc00" position="20" words="おしらせ" img="gohan" to="/news" />
          {children}
        <Footer />
      </AllCss>
    </div>
  );
};

export default TemplateWrapper;

const AllCss = styled.div`
  background-color: #f6f5f1;
  font-family: 'Noto Sans JP', sans-serif;
`