import React from 'react'
import { Link, navigate } from 'gatsby'
import styled from "styled-components";
import media from "styled-media-query";

import NAWATELogo from "../img/nawate-logo.svg";
import Instagram from "../img/Instagram.svg";
import ShopIcon from "../img/shop-icon.png";

const Footer = () => {
  return (
    <footer>
      <FooterBody>
        <MultiColumnDiv>
          {/*
          ロゴインスタSHOP運営アドレスのカラム
          トップページへのリンクカラム
          その他のページへのリンクカラム
          */}
          <ColumnDiv>
            <FadeLogo src={NAWATELogo} onClick={()=>{navigate("/")}}></FadeLogo>
            <Nest2ColumnDiv>
            <FlexA href="https://www.instagram.com/nawate_project/" target="_blank">
              <FadeLogo src={Instagram}></FadeLogo>
            </FlexA>
            <FlexA href="https://shopnawate.stores.jp" target="_blank">
              <ShopButton>🛒 ONLINE SHOP</ShopButton>
            </FlexA>
            </Nest2ColumnDiv>
            <Honbun>運営会社：合同会社さんさんごご<br />
              ✉3355.nawate@gmail.com</Honbun>
          </ColumnDiv>
          <ColumnLinkDiv>
            <StyledLink to="/">トップページ -</StyledLink>
          </ColumnLinkDiv>
          <ColumnLinkDiv>
            <StyledLink to="/facility">施設案内　　　　　　　-</StyledLink>
            <StyledLink to="/project">プロジェクト　　　　　-</StyledLink>
            <StyledLink to="/aboutus">私たちのこと　　　　　-</StyledLink>
            <StyledLink to="/archive">アーカイブ　　　　　　-</StyledLink>
            <StyledLink to="/news">おしらせ・イベント　　-</StyledLink>
          </ColumnLinkDiv>
          <ColumnLinkDiv></ColumnLinkDiv>
        </MultiColumnDiv>
        <br />
        <br />
        <br />
        <Honbun>ⓒNAWATE project All Copyrights Reserved.</Honbun>
        <br />
      </FooterBody>
    </footer>
  )
}

export default Footer;

const FooterBody = styled.div`
  background: #ffffff;
  padding: 5vh 5vw 10vh 5vw;
`;

const Nest2ColumnDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 30px 0;
  padding: 0 30px 0 30px;
`;

const FlexA = styled.a`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: none;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
  `}
`;

const MultiColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
  `}
`;

const StyledLink = styled(props => <Link {...props} />)`
  font-weight: 700;
  text-decoration: none;
  color: #000000;
  &:hover{
    opacity: 0.5;
  }
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3vh 0 0 0;
  line-height: 3em;
`;

const ColumnLinkDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: none;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    flex-direction: column;
    margin: 3vh 0 0 0;
    line-height: 3em;
  `}
`;

const Honbun = styled.p`
  text-align: center;
`;

const FadeLogo = styled.img`
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all  0.3s ease;
  &:hover{
    opacity: 0.5;
  }
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 30vw;
    margin: auto;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
  `}
`;

const ShopButton = styled.button`
  background: #333333;
  width: 10vw;
  height: 40px;
  margin: 0 0 0 2em;
  border: none;
  border-radius: 50px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 10pt;
  color: #ffffff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all  0.3s ease;

  &:hover {
    background: #f6f5f1;
    border: 1px #666666 solid;
    color: #666666;
    pointer: cursor;
  }
`;
