import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

import HumburgerMenu from "./HumburgerMenu";

const Humburger = ({open, setOpen}) =>{
  return (
    <div>
    <HumburgerDiv onClick={() => {setOpen(!open)}}>
      <Line />
      <Line />
      <Line />
      <MenuSpan>MENU</MenuSpan>
    </HumburgerDiv>
    <HumburgerMenu open={open} setOpen={setOpen} />
    </div>
  );
};

export default Humburger;

const HumburgerDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 30vw;
    margin: 0 3vw 0 3vw;
    text-align: right;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: none;
  `}
`;

const Line = styled.div`
  display: block;
  background: #333333;
  border-radius: 2px;
  width: 2.5em;
  height: 1px;
  margin: 1.5vh 0 0 auto;
`;

const MenuSpan = styled.span`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 9pt;
  margin: 0 1vw 0 0;
`;
