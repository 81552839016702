import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

import InstagramImage from "../img/Instagram.svg";

const Menu = () => {
    return (
      <MenuDiv>
        <StyledLink to="/">
            トップページ
        </StyledLink>
        <StyledLink to="/facility">
            施設案内
        </StyledLink>
        <StyledLink to="/project">
            プロジェクト
        </StyledLink>
        <StyledLink to="/aboutus">
            私たちのこと
        </StyledLink>
        <StyledLink to="/archive">
            アーカイブ
        </StyledLink>
        <StyledLink to="/news">
            おしらせ・イベント
        </StyledLink>
        <MultiColumnDiv>
          <FlexA href="https://www.instagram.com/nawate_project/" target="_blank">
          <InstagramLogo src={InstagramImage}></InstagramLogo>
          </FlexA>
          <FlexA href="https://shopnawate.stores.jp" target="_blank">
          <ShopButton>🛒 ONLINE SHOP</ShopButton>
          </FlexA>
          {/*
          <ShopButton><img src={ShopIcon} /> ONLINE SHOP</ShopButton>
          */}
        </MultiColumnDiv>
      </MenuDiv>
    );
};

export default Menu;

const MenuDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: none;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5vh 5vw 3vh 5vw;
  `}
`;

const MultiColumnDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexA = styled.a`
  display: flex;
`;

const StyledLink = styled(props => <Link {...props} />)`
  font-weight: 700;
  text-decoration: none;
  color: #000000;
  &:hover{
    text-decoration: underline;
    text-decoration-color: #666666;
    text-underline-offset: 5px;
    text-decoration-thickness: 4px;
  }
`;

const InstagramLogo = styled.img`
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all  0.3s ease;
  &:hover{
    opacity: 0.5;
  }
`;

const ShopButton = styled.button`
  background: #333333;
  width: 10vw;
  height: 40px;
  margin: 0 0 0 2em;
  border: none;
  border-radius: 50px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 10pt;
  color: #ffffff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all  0.3s ease;

  &:hover {
    background-color: rgba(0,0,0,0);
    border: 1px #666666 solid;
    color: #666666;
    pointer: cursor;
  }
`;