import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import media from "styled-media-query";

import BedIcon1 from "../img/bed-icon.png";
import ScheduleIcon1 from "../img/schedule-icon.png";

const FixedButton = ({
  color, position, words, img, to
}) =>{
  let imgSrc = null;
  if(img == "bed"){
    imgSrc = BedIcon1;
  }
  else{
    imgSrc = ScheduleIcon1;
  }
  return (
    <SideButton color={color} position={position} onClick={()=>{navigate(to)}}>
      <ButtonInsideDiv>
      <img src={imgSrc} /> <span dangerouslySetInnerHTML={{__html: words}}/>
      </ButtonInsideDiv>
      </SideButton>
  );
};

export default FixedButton;

const ButtonInsideDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    margin: 0 0 2vh 0;
  `}
`;

const SideButton = styled.button.attrs(props => ({
  color: props.color,
  position: props.position
}))`
  position: fixed;
  z-index: 10000;
  background: ${props => props.color};
  border: none;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 14pt;
  color: #2b2523;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all  0.3s ease;

  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    bottom: 0;
    left: ${props => (props.position - 10) * 5}vw;
    width: 50vw;
    height: 4em;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    right: 0;
    top: ${props => props.position}em;
    width: 2.5em;
    height: 10em;
    border-radius: 10px 0 0 10px;
    line-height: 1.3em;
    writing-mode: vertical-rl;

    &:hover {
      background-color: rgba(0,0,0,0);
      border: 1px ${props => props.color} solid;
      color: #000000;
      pointer: cursor;
    }
  `}

`;